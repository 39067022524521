"use strict";

namespace app {

    function getDigitPath(digit: number, digitIndex: number): string
    {
        const glyphPaths: string[] = [
            "84.872,37.902_c4.744,0 7.424,-3.705 7.424,-10.281c-0,-6.576 -2.68,-10.281 -7.424,-10.281c-4.73,-0 -7.41,3.705 -7.41,10.281c-0,6.576 2.68,10.281 7.41,10.281Zm-3.609,-10.281c-0,-4.58 1.271,-7.069 3.609,-7.069c1.545,0 2.611,1.067 3.172,3.09l-6.781,4.485l-0,-0.506Zm3.609,7.068c-1.531,0 -2.598,-1.053 -3.158,-3.062l6.781,-4.485l0,0.479c0,4.594 -1.271,7.068 -3.623,7.068Z",
            "92.528,37.492_l0,-3.24l-5.045,-0l0,-16.489l-3.8,0l-5.332,3.569l-0,3.664l5.086,-3.377l0.246,-0l-0,12.633l-5.319,-0l0,3.24l14.164,-0Z",
            "77.79,23.711_l0,0.082l3.609,-0l0,-0.096c0,-1.887 1.313,-3.172 3.268,-3.172c1.914,0 3.158,1.121 3.158,2.83c0,1.326 -0.697,2.365 -3.39,5.059l-6.426,6.357l-0,2.721l13.904,-0l0,-3.268l-8.627,0l0,-0.246l3.856,-3.677c3.336,-3.2 4.47,-5.045 4.47,-7.206c0,-3.418 -2.748,-5.728 -6.822,-5.728c-4.142,-0 -7,2.57 -7,6.344Z",
            "82.466,28.838_l2.351,-0c2.27,-0 3.733,1.134 3.733,2.898c-0,1.736 -1.436,2.844 -3.692,2.844c-2.119,-0 -3.541,-1.067 -3.677,-2.748l-3.596,-0c0.178,3.691 3.076,6.056 7.383,6.056c4.416,0 7.437,-2.392 7.437,-5.892c0,-2.612 -1.586,-4.362 -4.252,-4.676l0,-0.246c2.161,-0.438 3.487,-2.147 3.487,-4.471c-0,-3.117 -2.721,-5.236 -6.727,-5.236c-4.17,-0 -6.808,2.269 -6.945,5.975l3.459,-0c0.123,-1.737 1.422,-2.844 3.363,-2.844c1.983,-0 3.24,1.039 3.24,2.679c0,1.655 -1.285,2.735 -3.254,2.735l-2.31,-0l-0,2.926Z",
            "86.431,37.492_l3.636,-0l0,-3.733l2.625,0l0,-3.117l-2.679,0l-0,-5.045l-3.459,0l-0,4.977l-4.813,-0l0,-0.246l5.797,-12.565l-3.924,0l-6.002,12.879l0,3.117l8.819,0l-0,3.733Z",
            "84.763,37.888_c4.375,0 7.314,-2.802 7.314,-7.013c0,-3.842 -2.543,-6.508 -6.179,-6.508c-1.846,-0 -3.336,0.684 -4.184,1.928l-0.246,-0l0.424,-5.264l8.982,-0l0,-3.268l-12.059,0l-0.929,11.403l3.746,-0c0.711,-1.19 1.791,-1.791 3.213,-1.791c2.105,-0 3.541,1.449 3.541,3.595c-0,2.133 -1.422,3.569 -3.541,3.569c-1.832,-0 -3.213,-1.067 -3.446,-2.653l-3.746,0c0.11,3.569 2.981,6.002 7.11,6.002Z",
            "92.515,30.888_c-0,-3.636 -2.571,-6.343 -6.043,-6.343c-1.231,-0 -2.434,0.451 -3.158,1.216l-0.274,-0.041c0.15,-0.328 0.287,-0.574 0.889,-1.38l4.963,-6.577l-4.389,0c-0.52,0.657 -3.514,4.512 -4.156,5.483c-1.969,2.789 -2.803,5.004 -2.803,7.383c-0,4.32 3.062,7.259 7.533,7.259c4.252,0 7.438,-3.007 7.438,-7Zm-7.506,3.705c-2.078,0 -3.651,-1.517 -3.651,-3.568c0,-2.051 1.573,-3.568 3.651,-3.568c2.092,-0 3.65,1.517 3.65,3.568c0,2.051 -1.558,3.568 -3.65,3.568Z",
            "79.567,37.492_l4.075,-0l8.353,-16.475l0,-3.267l-14.027,-0l-0,3.24l10.117,-0l0,0.232l-8.518,16.27Z",
            "84.899,37.902_c4.635,0 7.766,-2.31 7.766,-5.728c0,-2.434 -1.682,-4.403 -4.279,-4.991l-0,-0.246c2.133,-0.506 3.486,-2.174 3.486,-4.252c0,-3.131 -2.898,-5.332 -6.973,-5.332c-4.087,0 -6.986,2.201 -6.986,5.332c0,2.078 1.354,3.746 3.486,4.252l0,0.246c-2.611,0.616 -4.279,2.571 -4.279,4.991c0,3.418 3.131,5.728 7.779,5.728Zm0,-12.1c-1.9,0 -3.158,-1.121 -3.158,-2.761c0,-1.641 1.258,-2.762 3.158,-2.762c1.887,0 3.145,1.121 3.145,2.762c-0,1.64 -1.258,2.761 -3.145,2.761Zm0,9.106c-2.242,-0 -3.759,-1.258 -3.759,-3.104c-0,-1.845 1.517,-3.103 3.759,-3.103c2.229,-0 3.747,1.258 3.747,3.103c-0,1.846 -1.518,3.104 -3.747,3.104Z",
            "77.421,24.353_c-0,3.692 2.543,6.344 6.043,6.344c1.23,-0 2.42,-0.451 3.144,-1.203l0.274,0.041c-0.151,0.328 -0.369,0.656 -0.902,1.381l-4.95,6.562l4.375,0c0.52,-0.629 3.514,-4.498 4.157,-5.469c1.996,-2.816 2.83,-5.031 2.83,-7.396c-0,-4.32 -3.063,-7.26 -7.534,-7.26c-4.238,0 -7.437,3.008 -7.437,7Zm7.506,-3.705c2.092,0 3.65,1.518 3.65,3.569c0,2.05 -1.558,3.582 -3.65,3.582c-2.092,-0 -3.651,-1.532 -3.651,-3.582c0,-2.051 1.559,-3.569 3.651,-3.569Z",
        ];
        const letterSpacingX: number = 25.71;
        const letterSpacingY: number = 30.26;
        const digitsPerRow : number = 4;

        if ((digit > 9) || (digit < 0))
        {
            throw new RangeError(`Invalid glyph digit ${digit}. (valid: 0-9)`);
        }

        const rawGlyph : string = glyphPaths[digit];
        const glyphPositionAndPath : string[] = rawGlyph.split('_');
        const glyphPosition: string[] = glyphPositionAndPath[0].split(',');

        const gridX: number = Math.trunc(digitIndex % digitsPerRow);
        const gridY: number = Math.trunc(digitIndex / digitsPerRow);

        let baseX: number = parseFloat(glyphPosition[0]);
        let baseY: number = parseFloat(glyphPosition[1]);
        baseX += gridX * letterSpacingX;
        baseY += gridY * letterSpacingY;

        return `<path id="Digit${digitIndex}_${digit}" d="M${baseX},${baseY}${glyphPositionAndPath[1]}" style="fill-rule:nonzero;"/>`;
    }

    function bindFormChange(): void
    {
        getFormElements().forEach( x => {
            x.addEventListener('change', event => {
                reloadQr();
            })
        });
    }

    function bindSvgDownload()
    {
        document.getElementById('svg-wrapper').addEventListener('click', event => {
            if (queryFormValidity()){
                let doc : string = document.getElementById('svg-wrapper').innerHTML;
                saveFile(`HomeKit_${(document.getElementById('input-setup-code') as HTMLInputElement).value}.svg`, 'data:application/octet-stream;base64,' + btoa(doc));
            }
        });
    }

    function reloadQr(): void
    {
        if (!queryFormValidity())
        {
            return;
        }
        const url: string= getHomeKitUrl();
        const qr = qrcodegen.QrCode.encodeText(url, qrcodegen.QrCode.Ecc.LOW);

        let setupCode: number = parseInt((document.getElementById('input-setup-code') as HTMLInputElement).value);

        const qrDimensions : number = 149.771;
        const qrOriginX : number = 17.114;
        const qrOriginY : number = 82.689;
        const cellWidth : number = qrDimensions / qr.size;
        let cells: string = '';

        for (let y = 0; y < qr.size; y++) {
            for (let x = 0; x < qr.size; x++) {
                if (qr.getModule(x, y)) {
                    const currentCellX: number = qrOriginX + cellWidth * x;
                    const currentCellY: number = qrOriginY + cellWidth * y;
                    cells += `<rect id=QR_${x}_${y}" x="${currentCellX}" y="${currentCellY}" width="${cellWidth}" height="${cellWidth}"/>`;
                }
            }
        }

        let digits : number[] = [];
        for (let i : number = 0; i < 8; i++)
        {
            digits.push(setupCode % 10);
            setupCode /= 10;
            setupCode = Math.trunc(setupCode);
        }
        digits = digits.reverse();

        let digitPath: string = '';
        for (let i : number = 0; i < 8; i++)
        {
            digitPath += getDigitPath(digits[i], i);
        }


        const svg : string =
            `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 184 250" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g id="MultilineSetupCode">
        <g id="SetupCodeBottom">
            ${digitPath}
        </g>
    </g>

    <path id="Border" d="M184,18.4c0,-10.155 -8.245,-18.4 -18.4,-18.4l-147.2,0c-10.155,0 -18.4,8.245 -18.4,18.4l0,213.2c0,10.155 8.245,18.4 18.4,18.4l147.2,-0c10.155,-0 18.4,-8.245 18.4,-18.4l0,-213.2Zm-4.5,0l0,213.2c0,7.672 -6.228,13.9 -13.9,13.9l-147.2,-0c-7.672,-0 -13.9,-6.228 -13.9,-13.9l0,-213.2c0,-7.672 6.228,-13.9 13.9,-13.9l147.2,-0c7.672,-0 13.9,6.228 13.9,13.9Z"/>

    ${cells}

    <g id="HomeKitLogo">
        <path id="HomeShapeExterior" d="M69.304,36.041c-0.252,-0.199 -2.446,-1.931 -5.497,-4.34l0.001,-0l-0,-9.859c-0,-0.67 -0.266,-0.861 -0.741,-0.861l-5.022,0c-0.55,0 -0.884,0.108 -0.884,0.861l-0,4.61l-0.002,-0.001c-7.262,-5.735 -15.137,-11.954 -15.436,-12.19c-0.596,-0.471 -0.964,-0.594 -1.387,-0.594c-0.42,0 -0.791,0.123 -1.387,0.594c-0.596,0.47 -26.789,21.155 -27.581,21.78c-0.967,0.763 -0.695,1.862 0.381,1.862l5.115,0l-0,27.749c-0,1.793 0.717,2.51 2.423,2.51l42.097,-0c1.707,-0 2.424,-0.717 2.424,-2.51l-0,-27.749l5.115,0c1.075,0 1.348,-1.099 0.381,-1.862Zm-9.798,25.717c-0,1.241 -0.638,2.102 -1.933,2.102l-34.475,-0c-1.294,-0 -1.932,-0.861 -1.932,-2.102l-0,-25.934c-0,-1.506 0.658,-2.467 1.41,-3.061c0.752,-0.594 16.139,-12.744 16.577,-13.09c0.439,-0.347 0.814,-0.496 1.183,-0.496c0.357,-0 0.744,0.149 1.182,0.496c0.439,0.346 15.825,12.496 16.577,13.09c0.752,0.594 1.411,1.555 1.411,3.061c-0,1.183 -0,24.909 -0,25.934Z" style="fill-rule:nonzero;"/>
        <path id="HomeShapeMid" d="M54.145,35.217c-0.45,-0.357 -12.509,-9.878 -12.831,-10.133c-0.323,-0.254 -0.669,-0.386 -0.978,-0.386c-0.312,-0 -0.656,0.132 -0.978,0.386c-0.323,0.255 -12.382,9.776 -12.831,10.133c-0.796,0.631 -1.059,1.276 -1.059,2.397l0,20.251c0,1.155 0.652,1.692 1.613,1.692l26.509,0c0.961,0 1.613,-0.537 1.613,-1.692l0,-20.251c0,-1.121 -0.262,-1.766 -1.058,-2.397Zm-3.244,18.754c0,0.926 -0.514,1.284 -1.219,1.284l-18.693,0c-0.704,0 -1.219,-0.358 -1.219,-1.284l0,-14.567c0,-0.65 0,-1.166 0.708,-1.734c0.465,-0.374 8.834,-6.976 9.084,-7.174c0.251,-0.198 0.497,-0.301 0.774,-0.301c0.261,-0 0.522,0.103 0.773,0.301c0.251,0.198 8.619,6.8 9.085,7.174c0.707,0.568 0.707,1.084 0.707,1.734l0,14.567Z" style="fill-rule:nonzero;"/>
        <path id="HomeShapeInternal" d="M34.933,50.953l10.806,-0c0.502,-0 0.86,-0.161 0.86,-0.876l0,-8.883c0,-0.473 -0.197,-0.961 -0.612,-1.298c-0.221,-0.179 -4.914,-3.856 -5.082,-3.988c-0.169,-0.133 -0.419,-0.208 -0.569,-0.208c-0.151,-0 -0.401,0.075 -0.569,0.208c-0.168,0.132 -4.862,3.809 -5.082,3.988c-0.415,0.337 -0.613,0.825 -0.613,1.298l0,8.883c0,0.715 0.359,0.876 0.861,0.876Z" style="fill-rule:nonzero;"/>
    </g>
</svg>
`;

        document.getElementById('svg-wrapper').innerHTML = svg;
        (document.getElementById('input-setup-payload') as HTMLInputElement).value = url;
    }

    function saveFile(fileName: string, url: string): void
    {
        const anchorElement: HTMLAnchorElement = document.createElement('a');
        anchorElement.href = url;
        anchorElement.download = fileName ?? '';
        anchorElement.click();
        anchorElement.remove();
    }

    function getFormElements() : NodeList
    {
        const accessoryElements: Element = document.getElementById('accessory-form');
        return accessoryElements.querySelectorAll('input:not([readonly]),select');
    }

    function queryFormValidity(): boolean
    {
        let result = true;
        getFormElements().forEach( x => {
            result &&= (<HTMLInputElement>x).checkValidity();
        });
        return result;
    }

    function getHomeKitUrl(): string
    {
        if (!queryFormValidity())
        {
            return;
        }

        const checkNfc = document.getElementById('input-flags-nfc') as HTMLInputElement;
        const checkIp = document.getElementById('input-flags-ip') as HTMLInputElement;
        const checkBtle = document.getElementById('input-flags-btle') as HTMLInputElement;

        let protocol = 0;
        if (checkNfc.checked)
        {
            protocol |= 1;
        }
        if (checkIp.checked)
        {
            protocol |= 2;
        }
        if (checkBtle.checked)
        {
            protocol |= 4;
        }

        let inCategory: string = (document.getElementById('input-category') as HTMLSelectElement).value;
        let inReserved: string = (document.getElementById('input-reserved') as HTMLInputElement).value;
        let inVersion: string = (document.getElementById('input-version') as HTMLInputElement).value;
        let inSetupCode: string = (document.getElementById('input-setup-code') as HTMLInputElement).value;
        let inSetupId: string = (document.getElementById('input-setup-id') as HTMLInputElement).value;

        return homekit.Accessory.GetCode(parseInt(inVersion), parseInt(inReserved), parseInt(inCategory), protocol, parseInt(inSetupCode), inSetupId);
    }

    function main(): void {
        bindFormChange();
        bindSvgDownload();
        reloadQr();
    }

    main();
}